import type { User } from '@models/user'
import { UserFilters } from '@models/user'
import type { AxiosError } from 'axios'
import { useQuery } from 'react-query'
import usersApi from './actions'
import { usersKeys } from './constants'

interface UseUsersData {
  users: User[]
  usersMap: Map<string, User>
}

export const selectData = (data: User[]): UseUsersData => ({
  users: data,
  usersMap: new Map(data.map(user => [user.id, user])),
})

export const useUsers = (filters: UserFilters) => {
  const query = useQuery<User[], AxiosError, UseUsersData>(
    usersKeys.users(filters),
    () => usersApi.getUsers(filters),
    {
      enabled: !!filters?.companyId,
      select: selectData,
    },
  )

  return query
}

export default useUsers
