import type {
  FinanceFilters,
  FinanceOperation,
  UseFinanceData,
} from '@models/finance'
import type { AxiosError } from 'axios'
import { useQuery } from 'react-query'
import financesApi from './actions'
import { financesKeys } from './constants'

export const selectData = (data: FinanceOperation[]): UseFinanceData => {
  return {
    finances: data,
    financesMap: new Map(data.map(finance => [finance.id, finance])),
  }
}
const useFinances = (filters: FinanceFilters) => {
  const query = useQuery<FinanceOperation[], AxiosError, UseFinanceData>(
    financesKeys.list(filters),
    () => financesApi.getFinances(filters),
    {
      select: selectData,
    },
  )

  return query
}

export default useFinances
