import type { Route, RouteFilters } from '@models/route'
import type { AxiosError } from 'axios'
import { useQuery } from 'react-query'
import routesApi from './actions'
import { routesKeys } from './constants'

interface UseRoutesData {
  routes: Route[]
  routesMap: Map<string, Route>
}

export const selectData = (data: Route[]): UseRoutesData => ({
  routes: data,
  routesMap: new Map(data.map(user => [user.id, user])),
})

const useRoutes = (filters: RouteFilters) => {
  const query = useQuery<Route[], AxiosError, UseRoutesData>(
    routesKeys.list(filters),
    () => routesApi.getRoutes(filters),
    {
      select: selectData,
      enabled: !!filters.companyId,
    },
  )

  return query
}

export default useRoutes
