import Text from '@components/typography/Text'
import Title from '@components/typography/Title'
import CrossIcon from '@public/icons/cross.svg'
import TickIcon from '@public/icons/tick.svg'
import { classnames } from '@tools/common'
import { useTranslation } from 'next-i18next'
import { Toaster as ToasterBase, resolveValue, toast } from 'react-hot-toast'

const Toaster = () => {
  const { t } = useTranslation()

  return (
    <ToasterBase position="top-right">
      {tBase => (
        <div
          className={classnames(
            'bg-white justify-between w-full max-w-[472px] flex gap-2 items-center border rounded-lg py-2.5 px-3 border-main-300',
            {
              'opacity-0': !tBase.visible,
            },
          )}
        >
          <div className="flex items-center">
            <div
              className={classnames('bg-main-900 w-1 rounded-full h-[54px]', {
                'bg-green-500': tBase.type === 'success',
                'bg-danger-500': tBase.type === 'error',
              })}
            />

            <div
              className={classnames('bg-main-100 p-2 rounded-full ml-[14px]', {
                'bg-danger-100': tBase.type === 'error',
                'bg-green-100': tBase.type === 'success',
              })}
            >
              <span
                className={classnames(
                  'w-[26px] bg-main-500 flex items-center justify-center h-[26px] rounded-full',
                  {
                    'bg-danger-500': tBase.type === 'error',
                    'bg-success-500': tBase.type === 'success',
                  },
                )}
              >
                {tBase.type === 'error' ? (
                  <CrossIcon className="text-white w-4 h-4" />
                ) : tBase.type === 'success' ? (
                  <TickIcon className="text-white w-4 h-4" />
                ) : (
                  <div />
                )}
              </span>
            </div>

            <div className="flex flex-col pl-5 capitalize">
              <Title as="h6" className="text-primary">
                {tBase.type === 'error'
                  ? t('ui.error')
                  : tBase.type === 'success'
                    ? t('ui.success')
                    : t('ui.loading')}
              </Title>

              <Text as="p" size="extra" className="text-text-500 capitalize">
                {resolveValue(tBase.message, tBase)}
              </Text>
            </div>
          </div>

          <button
            onClick={() => toast.remove(tBase.id)}
            className="text-main-500 hover:text-main-700"
          >
            <CrossIcon className="w-6 h-6" />
          </button>
        </div>
      )}
    </ToasterBase>
  )
}

export default Toaster
