import queryClient from '@api/queryClient'
import type { RouteFilters } from '@models/route'
import { useMutation } from 'react-query'
import routesApi from './actions'
import { routesKeys } from './constants'

const useRoutesMutations = (filters?: RouteFilters) => {
  const finishRoute = useMutation(routesApi.finishRoute, {
    onSuccess: async () => {
      await queryClient.invalidateQueries(routesKeys.list(filters))
    },
  })

  const processPlan = useMutation(routesApi.processPlan, {
    onSuccess: async () => {
      await queryClient.invalidateQueries(routesKeys.list(filters))
    },
  })

  return { finishRoute, processPlan }
}

export default useRoutesMutations
