import { userApi, usersKeys } from '@api'
import { UserFilters } from '@models/user'
import { useQuery } from 'react-query'

const useUserCount = (filters: UserFilters) => {
  const query = useQuery(
    usersKeys.usersCount(filters),
    () => userApi.getUserCount(filters),
    {
      enabled: !!filters.companyId,
      staleTime: Infinity,
    },
  )

  return query
}

export default useUserCount
