'use client'

import { useAuth } from '@api'
import { useEffect, useState } from 'react'

const useFreshChatWidget = () => {
  const authQuery = useAuth()

  const [isChatVisible, setIsChatVisible] = useState(false)

  const initializeWidget = () => {
    if (window.fcWidget) {
      window.fcWidget.init({})

      window.fcWidget.setConfig({
        headerProperty: {
          hideChatButton: true,
        },
      })

      window.fcWidget.user.setProperties({
        firstName: authQuery.data?.fullName,
        externalId: authQuery.data?.userExternalId,
        phone: authQuery.data?.phone,
        partner: authQuery.data?.companyPartnerId,
        id: authQuery.data?.id,
      })

      window.fcWidget.on('widget:opened', () => setIsChatVisible(true))
      window.fcWidget.on('widget:closed', () => setIsChatVisible(false))
    }
  }

  useEffect(() => {
    const checkWidget = () => {
      if (window.fcWidget) {
        initializeWidget()
      } else {
        setTimeout(checkWidget, 300)
      }
    }

    checkWidget()
  }, [])

  const toggleChat = () => {
    if (isChatVisible) {
      window.fcWidget.close()
    } else {
      window.fcWidget.open()
    }
  }

  const destroyChat = () => {
    if (window.fcWidget) {
      window.fcWidget.close()
    }
  }

  return { toggleChat, isChatVisible, destroyChat }
}

export default useFreshChatWidget
