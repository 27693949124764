import TickIcon from '@components/TickIcon'
import TableIconButton from '@components/buttons/TableIconButton'
import type { Company } from '@models/company'
import EditIcon from '@public/icons/edit.svg'
import { createColumnHelper } from '@tanstack/react-table'
import { useTranslation } from 'next-i18next'

interface UseCompanyColumnDefsProps {
  onClickEdit: (companyId: string) => void
}

const useCompanyColumnDefs = ({ onClickEdit }: UseCompanyColumnDefsProps) => {
  const { t } = useTranslation()

  const columnHelper = createColumnHelper<Company>()

  const columnDefs = [
    columnHelper.accessor(row => row.name, {
      id: 'name',
      cell: info => info.getValue(),
      header: () => t('pages.home.table.name'),
    }),
    columnHelper.accessor(row => row.companyExternalId, {
      id: 'id',
      cell: info => info.getValue(),
      header: () => 'ID',
    }),
    columnHelper.accessor(row => row.countryCode, {
      id: 'countryCode',
      cell: info => info.getValue(),
      header: () => t('pages.home.table.country_code'),
    }),
    columnHelper.accessor(row => row.domen, {
      id: 'domen',
      cell: info => info.getValue(),
      header: () => t('pages.home.table.domen'),
    }),
    columnHelper.accessor(row => row.voipAvailable, {
      id: 'voip',
      cell: info => (
        <p className="text-center">{<TickIcon value={info.getValue()} />}</p>
      ),
      header: () => <p className="text-center">{t('pages.home.table.voip')}</p>,
    }),
    columnHelper.accessor(row => row.simplifiedOrders, {
      id: 'simplifiedOrders',
      cell: info => (
        <p className="text-center">{<TickIcon value={info.getValue()} />}</p>
      ),
      header: () => (
        <p className="text-center">{t('pages.home.table.simplified_orders')}</p>
      ),
    }),
    columnHelper.accessor(row => row.proofDelivery, {
      id: 'proofDelivery',
      cell: info => (
        <p className="text-center">{<TickIcon value={info.getValue()} />}</p>
      ),
      header: () => (
        <p className="text-center">{t('pages.home.table.proof_delivery')}</p>
      ),
    }),
    columnHelper.accessor(row => row, {
      id: 'editCompany',
      size: 50,
      cell: cell => (
        <TableIconButton
          onClick={() => onClickEdit(cell.row.original.id)}
          icon={<EditIcon className="h-4 w-4" />}
        />
      ),
      header: () => <span />,
      enableResizing: false,
      enableSorting: false,
    }),
  ]

  return columnDefs
}

export default useCompanyColumnDefs
