'use client'

import { BaseProps, DynamicElementProps } from '@models/typography'

type HeadlineTag = 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6'

type HeadlineProps = DynamicElementProps<HeadlineTag> & BaseProps

const HEADLINE_SIZE: Record<HeadlineTag, string> = {
  h1: 'text-headline-1 font-bold font-montserrat',
  h2: 'text-headline-2 font-bold font-montserrat',
  h3: 'text-headline-3 font-medium',
  h4: 'text-headline-4 font-bold font-montserrat',
  h5: 'text-headline-5 font-bold font-montserrat',
  h6: 'text-headline-6 font-bold font-montserrat',
}

const Title = ({ as, className = '', children }: HeadlineProps) => {
  const TagName = as

  return (
    <TagName className={`${HEADLINE_SIZE[as]} ${className}`}>
      {children}
    </TagName>
  )
}

export default Title
