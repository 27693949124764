import Text from '@components/typography/Text'
import Title from '@components/typography/Title'
import { classnames } from '@tools/common'

interface ButtonProps {
  icon?: React.ReactNode
  label?: string
  title?: string
  onClick?: (e: React.MouseEvent) => void
  size?: 'default' | 'large' | 'title'
  className?: string
  disabled?: boolean
  type?: 'button' | 'submit'
  color?: 'primary' | 'secondary' | 'danger' | 'grey'
  form?: string
}

const Button = ({
  icon,
  label,
  onClick,
  size = 'default',
  className,
  type = 'button',
  disabled = false,
  color = 'primary',
  form,
}: ButtonProps) => {
  return (
    <button
      form={form}
      type={type}
      className={classnames(
        'bg-primary rounded-lg duration-300 transition-colors items-center justify-center text-white hover:bg-main-900 flex py-[11px] gap-1.5 px-6 w-fit',
        className,
        {
          'bg-white text-primary hover:bg-main-300': color === 'secondary',
          'bg-attention-500 hover:bg-attention-500': color === 'danger',
          'py-[13px]': !icon,
          'bg-main-100 hover:bg-main-300 text-main-900': color === 'grey',
          'bg-main-300 hover:bg-main-300': disabled,
        },
      )}
      disabled={disabled}
      onClick={onClick}
    >
      <div
        className={classnames({
          'opacity-20': disabled,
        })}
      >
        {icon && icon}
      </div>

      {label && size !== 'title' && (
        <Text
          as="span"
          size={size === 'default' ? 'subtitle-default' : 'subtitle-extra'}
        >
          {label}
        </Text>
      )}

      {label && size === 'title' && <Title as="h5">{label}</Title>}
    </button>
  )
}

export default Button
