import type { OrderFilters } from '@models/order'
import { useMutation, useQueryClient } from 'react-query'
import ordersApi from './actions'
import { ordersKeys } from './constants'

const useOrdersMutations = (filters?: OrderFilters) => {
  const queryClient = useQueryClient()

  const cancelOrder = useMutation(ordersApi.cancelOrder, {
    onSuccess: async () => {
      await queryClient.invalidateQueries(ordersKeys.orders(filters))
    },
  })

  return { cancelOrder }
}

export default useOrdersMutations
