import type { AuthData, User } from '@models/user'
import * as ls from 'local-storage'

export const ACCESS_TOKEN_KEY = 'accessToken' as const
export const REFRESH_TOKEN_KEY = 'refreshToken' as const

export const COMPANY_EXTERNAL_ID_KEY = 'companyExternalId' as const
export const COMPANY_ID_KEY = 'companyId' as const

export const getAuthCredentials = () => {
  const accessToken = ls.get<string>(ACCESS_TOKEN_KEY)
  const refreshToken = ls.get<string>(REFRESH_TOKEN_KEY)
  return { accessToken, refreshToken }
}

export const setAuthCredentials = (data: AuthData) => {
  ls.set<string>(ACCESS_TOKEN_KEY, data.accessToken)
  ls.set<string>(REFRESH_TOKEN_KEY, data.refreshToken)
}

export const setCompanyIds = (companyExternalId: string, companyId: string) => {
  ls.set<string>(COMPANY_EXTERNAL_ID_KEY, companyExternalId)
  ls.set<string>(COMPANY_ID_KEY, companyId)
}

export const clearAuthCredentials = () => {
  ls.remove(ACCESS_TOKEN_KEY)
  ls.remove(REFRESH_TOKEN_KEY)
  ls.remove(COMPANY_EXTERNAL_ID_KEY)
  ls.remove(COMPANY_ID_KEY)
}

export const getAuthCredentialsExist = () => {
  return Object.values(getAuthCredentials()).every(Boolean)
}

export const isRinkaiOwnerUser = (user: User) => user.role === 'RINKAI_OWNER'

export const isRinkaiManagerUser = (user: User) =>
  user.role === 'RINKAI_MANAGER'

export const isCompanySuperAdminUser = (user: User) =>
  user.role === 'COMPANY_SUPER_ADMIN'

export const isCompanyRegionalManagerUser = (user: User) =>
  user.role === 'COMPANY_REGIONAL_MANAGER'

export const isCompanyManagerUser = (user: User) =>
  user.role === 'COMPANY_MANAGER'

export const isCompanyDispatcherUser = (user: User) =>
  user.role === 'COMPANY_DISPATCHER'

export const isCompanyDriverUser = (user: User) =>
  user.role === 'COMPANY_DRIVER'

export const isTmsUser = (user: User) => user.role.startsWith('TMS')

export const isCompanyUser = (user: User) => user.role.startsWith('COMPANY')
