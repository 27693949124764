import type { User } from '@models/user'
import type { AxiosError } from 'axios'
import { useQuery } from 'react-query'
import usersApi from './actions'
import { usersKeys } from './constants'

export const useUser = (id?: string) => {
  const query = useQuery<User, AxiosError>(
    usersKeys.user(id!),
    () => usersApi.getUser(id!),
    { enabled: !!id },
  )

  return query
}

export default useUser
