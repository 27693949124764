import { keyToUrl } from '@tools/common'

export const depotsKeys = {
  root: (companyId?: string) => ['depots', companyId ?? null] as const,
  depot: (id: string) => ['depots', id] as const,
}

export const depotsUrls = {
  root: keyToUrl(depotsKeys.root()),
  depot: (id: string) => keyToUrl(depotsKeys.depot(id)),
}
