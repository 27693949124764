import type { WaresToDepotFilters, WareToDepot } from '@models/ware'
import type { AxiosError } from 'axios'
import { useQuery } from 'react-query'
import { waresApi } from '.'
import { waresKeys } from './constants'

interface UseWaresToDepotData {
  wares: WareToDepot[]
  waresMap: Map<string, WareToDepot>
}

export const selectData = (data: WareToDepot[]): UseWaresToDepotData => ({
  wares: data,
  waresMap: new Map(data.map(ware => [ware.id, ware])),
})

const useWaresToDepot = (filters: WaresToDepotFilters) => {
  const query = useQuery<WareToDepot[], AxiosError, UseWaresToDepotData>(
    waresKeys.waresToDepot(filters),
    () => waresApi.getWaresToDepot(filters),
    {
      select: selectData,
      enabled: !!filters.companyId,
    },
  )

  return query
}

export default useWaresToDepot
