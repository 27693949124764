import type { Order } from '@models/order'
import type { AxiosError } from 'axios'
import { useQuery } from 'react-query'
import ordersApi from './actions'
import { ordersKeys } from './constants'

const useOrder = (id: string) => {
  const query = useQuery<Order, AxiosError>(ordersKeys.order(id), () =>
    ordersApi.getOrder(id),
  )

  return query
}

export default useOrder
