import { waresApi, waresKeys } from '@api'
import { WaresToDepotFilters } from '@models/ware'
import { useQuery } from 'react-query'

const useWaresToDepotCount = (filters: WaresToDepotFilters) => {
  const query = useQuery(waresKeys.waresToDepotCount(filters), () =>
    waresApi.getWaresToDepotCount(filters),
  )

  return query
}

export default useWaresToDepotCount
