import {
  useAuth,
  useCompanies,
  useCompanyPreferences,
  useUserFeatures,
} from '@api'
import { COMPANY_ID_KEY } from '@api/resources/auth/tools'
import {
  Tooltip,
  TooltipContent,
  TooltipTrigger,
} from '@components/shadcn/ui/tooltip'
import Text from '@components/typography/Text'
import useFreshChatWidget from '@hooks/useFreshChatWidget'
import { PreferencesConfig } from '@models/company'
import {
  DepotIcon,
  FinancesIcon,
  GoRampIcon,
  OrderIcon,
  PartnersIcon,
  ReportsIcon,
  RouteIcon,
  SupportIcon,
  UsersIcon,
  WaresToDepotIcon,
} from '@public/icons/sidebar'
import CloseIcon from '@public/icons/sidebar/close.svg'
import { TooltipArrow, TooltipProvider } from '@radix-ui/react-tooltip'
import classnames from 'classnames'
import { get } from 'local-storage'
import { useTranslation } from 'next-i18next'
import Image from 'next/image'
import Link from 'next/link'
import { useRouter } from 'next/router'
import Script from 'next/script'
import { forwardRef, useEffect, useState } from 'react'
import ChangeLanguagePopUp from './ChangeLanguagePopUp'
import ProfilePopUp from './ProfilePopUp'
import LogoIcon from '/public/icons/logo.svg'

export interface SideBarItemsProps {
  Icon: React.ReactNode
  href?: string
  target?: '_blank'
  tipText: string
  onClick?: () => void
  isToggleCustomColors?: boolean
}

const Sidebar = forwardRef<
  HTMLElement,
  { className?: string; closeSidebar: () => void }
>(({ className, closeSidebar }, ref) => {
  const { t } = useTranslation()
  const router = useRouter()

  const [companyId, setCompanyId] = useState<string | null>(null)
  const [isClient, setIsClient] = useState(false)

  const authQuery = useAuth()
  const companyQuery = useCompanies()
  const userFeaturesQuery = useUserFeatures(authQuery.data?.id)
  const preferencesQuery = useCompanyPreferences()

  const { toggleChat, isChatVisible } = useFreshChatWidget()

  const company = companyQuery.data?.companiesMap.get(companyId ?? '')

  useEffect(() => {
    setCompanyId(get(COMPANY_ID_KEY))
    setIsClient(true)
  }, [])

  const isItemVisible = (key: keyof Omit<PreferencesConfig, 'settings'>) =>
    preferencesQuery.data?.[key]?.hidden !== true

  const getSidebarItems = () => {
    const defaultIconProps = {
      className: 'w-6 h-6',
    }

    const items: SideBarItemsProps[] = []

    if (
      authQuery.isSuccess &&
      companyId &&
      company &&
      router.asPath !== '/' &&
      router.asPath !== '/companies/create' &&
      router.pathname !== '/companies/[companyId]/edit'
    ) {
      if (isItemVisible('depots')) {
        items.push({
          Icon: <DepotIcon {...defaultIconProps} />,
          href: `/companies/${companyId}/depots`,
          tipText: t('pages.home.table.depots'),
        })
      }

      if (isItemVisible('users') && authQuery.data?.companyPartnerId === null) {
        items.push({
          Icon: <UsersIcon {...defaultIconProps} />,
          href: `/companies/${companyId}/users`,
          tipText: t('pages.home.table.users'),
        })
      }

      if (
        preferencesQuery.data?.partners &&
        isItemVisible('partners') &&
        authQuery.data?.companyPartnerId === null
      ) {
        items.push({
          Icon: <PartnersIcon {...defaultIconProps} />,
          href: `/companies/${companyId}/partners`,
          tipText: t('pages.home.table.partners'),
        })
      }

      if (isItemVisible('orders')) {
        items.push({
          Icon: <OrderIcon {...defaultIconProps} />,
          href: `/companies/${companyId}/orders`,
          tipText: t('pages.home.table.orders'),
        })
      }

      if (isItemVisible('routes')) {
        items.push({
          Icon: <RouteIcon {...defaultIconProps} />,
          href: `/companies/${companyId}/routes`,
          tipText: t('pages.home.table.routes'),
        })
      }

      if (
        company &&
        !company.simplifiedOrders &&
        isItemVisible('waresToDepot')
      ) {
        items.push({
          Icon: <WaresToDepotIcon {...{ ...defaultIconProps, size: 30 }} />,
          href: `/companies/${companyId}/waresToDepot`,
          tipText: t('pages.companies.users.form.wares_to_depot'),
        })
      }

      if (company && !company.simplifiedOrders && isItemVisible('finances')) {
        items.push({
          Icon: <FinancesIcon {...{ ...defaultIconProps, size: 30 }} />,
          href: `/companies/${companyId}/finances`,
          tipText: t('pages.companies.users.form.finances'),
        })
      }

      if (userFeaturesQuery.data?.via3lReports?.enabled) {
        {
          const { login, password } = userFeaturesQuery.data.via3lReports

          items.push({
            Icon: <ReportsIcon {...{ ...defaultIconProps, size: 30 }} />,
            href:
              login && password
                ? `https://${login}:${password}@partners.via3l.lv/reports/browse/`
                : `https://partners.via3l.lv/reports/browse/`,
            target: '_blank',
            tipText: t('pages.home.table.reports'),
          })
        }
      }

      if (userFeaturesQuery.data?.goRamp?.enabled) {
        items.push({
          Icon: <GoRampIcon {...{ ...defaultIconProps, size: 30 }} />,
          href: `https://app.goramp.eu`,
          target: '_blank',
          tipText: 'GoRamp',
        })
      }

      if (userFeaturesQuery.data?.chat?.enabled) {
        items.push({
          Icon: <SupportIcon {...{ ...defaultIconProps, size: 30 }} />,
          onClick: toggleChat,
          tipText: t('pages.home.table.chat'),
          isToggleCustomColors: true,
        })
      }
    }

    return items
  }

  return (
    <aside
      ref={ref}
      className={classnames(
        'bg-primary h-dvh flex flex-col items-center text-neutral-content',
        className,
      )}
    >
      <div className="px-2.5 w-full md:hidden">
        <button
          onClick={closeSidebar}
          className="w-full flex justify-end  mt-2 px-1  rounded-lg border-main-500"
        >
          <CloseIcon />
        </button>
      </div>

      <TooltipProvider>
        {isClient ? (
          <Link
            href={
              preferencesQuery.data?.settings.homePage === 'orders'
                ? `/companies/${companyId}/orders?page=1&take=10`
                : '/'
            }
            className="pb-2.5 px-2.5 pt-0 sm:pt-2.5 flex items-center justify-center aspect-square rounded-full my-2.5 hover:shadow-2xl"
          >
            {!preferencesQuery.data?.settings.theme && (
              <LogoIcon height={35} width={40} />
            )}

            {preferencesQuery.data?.settings.theme === 'v1a3131' && (
              <Image
                src="/icons/via3l.webp"
                alt="logo"
                height={35}
                width={40}
              />
            )}
          </Link>
        ) : (
          <div className="w-10 h-[35px] pb-2.5 px-2.5 sm:pt-2.5" />
        )}

        <ul className="w-full h-full overflow-y-auto overflow-x-clip flex flex-col items-center">
          {getSidebarItems().map(
            (
              { Icon, href, tipText, target, onClick, isToggleCustomColors },
              index,
            ) => (
              <Tooltip key={index}>
                <TooltipTrigger asChild>
                  <li
                    onClick={() => {
                      if (onClick && !href) {
                        onClick()
                      } else if (target === '_blank' && href) {
                        window.open(href, target)
                      } else if (href) {
                        router.push(href)
                      }
                    }}
                    className={classnames(
                      'w-full flex justify-center cursor-pointer items-center hover:bg-white/10 hover:shadow-2xl aspect-square',
                      {
                        'bg-white/20 shadow-2xl':
                          router.asPath.split('?')[0] === href,
                        'bg-[#c4e398] hover:bg-[#8dc63f]':
                          isChatVisible && isToggleCustomColors,
                      },
                    )}
                  >
                    {Icon}
                  </li>
                </TooltipTrigger>

                <TooltipContent side="right" sideOffset={0}>
                  <TooltipArrow className="fill-primary" />
                  <Text
                    as="p"
                    size="caption-default"
                    className="px-3 py-2 uppercase"
                  >
                    {tipText}
                  </Text>
                </TooltipContent>
              </Tooltip>
            ),
          )}
        </ul>

        <Tooltip>
          <TooltipTrigger asChild>
            <div>
              <ChangeLanguagePopUp />
            </div>
          </TooltipTrigger>

          <TooltipContent side="right" sideOffset={0}>
            <TooltipArrow className="fill-primary" />
            <Text as="p" size="caption-default" className="px-3 py-2 uppercase">
              {t('ui.language.title')}
            </Text>
          </TooltipContent>
        </Tooltip>

        <Tooltip>
          <TooltipTrigger asChild>
            <div>
              <ProfilePopUp
                fullName={authQuery.data?.fullName}
                login={authQuery.data?.login}
              />
            </div>
          </TooltipTrigger>

          <TooltipContent side="right" sideOffset={0}>
            <TooltipArrow className="fill-primary" />
            <Text as="p" size="caption-default" className="px-3 py-2 uppercase">
              {t('ui.logout')}
            </Text>
          </TooltipContent>
        </Tooltip>
      </TooltipProvider>

      {userFeaturesQuery.data?.chat?.enabled && (
        <Script
          src={process.env.NEXT_PUBLIC_FRESHCHAT_SCRIPT_URL}
          async
          strategy="lazyOnload"
        />
      )}
    </aside>
  )
})

export default Sidebar
