import httpClient from '@api/httpClient'
import { PREFERENCES } from '@constants/preferences'
import type { Company, CompanyPartners } from '@models/company'
import { companiesUrls } from './constants'

const getCompanies = async () => {
  const { data } = await httpClient.get<Company[]>(companiesUrls.root)
  return data.filter(company => company.companyExternalId !== 'Rinkai')
}

interface CreateCompanyRequestData extends Partial<Company> {
  uploadLogo?: Blob
}

const createCompany = async ({
  logo,
  uploadLogo,
  ...requestData
}: CreateCompanyRequestData) => {
  const formData = new FormData()

  if (uploadLogo) {
    formData.append('logo', uploadLogo, 'logo.png')
  }

  Object.entries(requestData).forEach(([key, value]) => {
    if (value === undefined) {
      return
    }

    if (typeof value === 'string') {
      formData.append(key, value)
    } else {
      formData.append(key, JSON.stringify(value))
    }
  })

  const { data } = await httpClient.postForm<Company>(
    companiesUrls.root,
    formData,
  )

  return data
}

const getCompanyPreferences = (companyExternalId: string) => {
  return PREFERENCES[companyExternalId]
}

const getCompanyPartners = async (companyId: string) => {
  const { data } = await httpClient.get<CompanyPartners[]>(
    companiesUrls.companyPartners(companyId),
  )
  return data
}

const createCompanyPartner = async (props: Omit<CompanyPartners, 'id'>) => {
  const { data } = await httpClient.post<CompanyPartners>(
    companiesUrls.companyPartners(props.companyId),
    {
      partnerExternalId: props.partnerExternalId,
      name: props.name,
    },
  )
  return data
}

const updateCompanyPartner = async (props: CompanyPartners) => {
  const { data } = await httpClient.patch<CompanyPartners>(
    companiesUrls.companyPartner(props.companyId, props.id),
    {
      partnerExternalId: props.partnerExternalId,
      name: props.name,
    },
  )
  return data
}

const deleteCompanyPartner = async ({
  companyId,
  partnersId,
}: {
  companyId: string
  partnersId: string
}) => {
  const { data } = await httpClient.delete<CompanyPartners>(
    companiesUrls.companyPartner(companyId, partnersId),
  )
  return data
}

interface UpdateCompanyRequestData extends Partial<Company> {
  id: string
  uploadLogo?: Blob
}

const updateCompany = async ({
  id,
  logo,
  uploadLogo,
  ...requestData
}: UpdateCompanyRequestData) => {
  const formData = new FormData()

  if (logo) {
    formData.append('logo', logo)
  }

  if (uploadLogo) {
    formData.append('logo', uploadLogo, 'logo.png')
  }

  Object.entries(requestData).forEach(([key, value]) => {
    if (value === undefined) {
      return
    }

    if (typeof value === 'string') {
      formData.append(key, value)
    } else {
      formData.append(key, JSON.stringify(value))
    }
  })

  const { data } = await httpClient.putForm<Company>(
    companiesUrls.company(id),
    formData,
  )

  return data
}

export default {
  getCompanies,
  createCompany,
  updateCompany,
  getCompanyPreferences,
  getCompanyPartners,
  createCompanyPartner,
  updateCompanyPartner,
  deleteCompanyPartner,
}
