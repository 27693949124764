import type { Ware } from '@models/ware'
import type { AxiosError } from 'axios'
import { useQuery } from 'react-query'
import waresApi from './actions'
import { waresKeys } from './constants'

const useWare = (id: string) => {
  const query = useQuery<Ware, AxiosError>(waresKeys.ware(id), () =>
    waresApi.getWare(id),
  )

  return query
}

export default useWare
