import { PreferencesConfig } from '@models/company'

export const getRouteToRedirect = (
  companyId?: string,
  preferences?: PreferencesConfig,
) => {
  if (
    companyId &&
    preferences &&
    preferences.settings &&
    preferences.settings.homePage &&
    preferences.settings.homePage === 'orders'
  ) {
    return `/companies/${companyId}/orders?page=1&take=10`
  }
  return null
}
