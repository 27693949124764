import queryClient from '@api/queryClient'
import type { WaresToDepotFilters } from '@models/ware'
import { useMutation } from 'react-query'
import waresApi from './actions'
import { waresKeys } from './constants'

const useWaresMutations = (filters: WaresToDepotFilters) => {
  const acceptWaresToDepot = useMutation(waresApi.acceptWaresToDepot, {
    onMutate: () => {},
    onSuccess: async () => {
      await queryClient.invalidateQueries(waresKeys.waresToDepot(filters))
    },
    onSettled: () => {},
  })

  return { acceptWaresToDepot }
}

export default useWaresMutations
