import type { Stop } from '@models/stop'
import type { AxiosError } from 'axios'
import { useQuery } from 'react-query'
import stopsApi from './actions'
import { stopsKeys } from './constants'

interface UseStopsData {
  stops: Stop[]
  stopsMap: Map<string, Stop>
}

export const selectData = (data: Stop[]): UseStopsData => {
  return {
    stops: data,
    stopsMap: new Map(data.map(route => [route.id, route])),
  }
}

const useStops = (routeId?: string) => {
  const query = useQuery<Stop[], AxiosError, UseStopsData>(
    stopsKeys.root(routeId),
    () => stopsApi.getStops(routeId!),
    { select: selectData, enabled: !!routeId },
  )

  return query
}

export default useStops
