import queryClient from '@api/queryClient'
import ThemeProvider from '@components/ThemeProvider'
import Toaster from '@components/Toaster'
import { Analytics } from '@vercel/analytics/react'
import { appWithTranslation } from 'next-i18next'
import type { AppProps } from 'next/app'
import { QueryClientProvider } from 'react-query'
import { ReactQueryDevtools } from 'react-query/devtools'
import '../styles/global.css'

const MyApp = ({ Component, pageProps }: AppProps) => {
  return (
    <QueryClientProvider client={queryClient}>
      <ThemeProvider>
        <Component {...pageProps} />
      </ThemeProvider>

      <Toaster />
      <Analytics />

      {process.env.NEXT_PUBLIC_DEVTOOLS_ENABLED && (
        <ReactQueryDevtools initialIsOpen={false} />
      )}
    </QueryClientProvider>
  )
}

export default appWithTranslation(MyApp)
