import Table from '@components/Table'
import useCompanyColumnDefs from '@hooks/useCompanyColumnDefs'
import type { Company } from '@models/company'
import {
  getCoreRowModel,
  getSortedRowModel,
  useReactTable,
} from '@tanstack/react-table'

interface CompaniesTableProps {
  data: Company[]
  onClickEdit: (companyId: string) => void
  onClickRow: (companyId: string) => void
}

const CompaniesTable = ({
  data,
  onClickEdit,
  onClickRow,
}: CompaniesTableProps) => {
  const columnDefs = useCompanyColumnDefs({ onClickEdit })

  const table = useReactTable({
    columns: columnDefs,
    data,
    getSortedRowModel: getSortedRowModel(),
    getCoreRowModel: getCoreRowModel(),
    columnResizeMode: 'onChange',
  })

  return <Table table={table} onClickRow={row => onClickRow(row.id)} />
}

export default CompaniesTable
