import httpClient from '@api/httpClient'
import type { Stop } from '@models/stop'
import { stopsUrls } from './constants'

const getStops = async (routeId: string) => {
  const { data } = await httpClient.get<Stop[]>(stopsUrls.root, {
    params: { routeId },
  })
  return data
}

export default {
  getStops,
}
