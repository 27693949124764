import {
  BaseProps,
  DynamicElementProps,
  TypographyProps,
} from '@models/typography'

export type TextSizeType =
  | 'extra'
  | 'default'
  | 'medium'
  | 'small'
  | 'subtitle-extra'
  | 'subtitle-default'
  | 'subtitle-medium'
  | 'subtitle-small'
  | 'caption-default'
  | 'caption-small'

type TextTag = 'p' | 'span'

type TextProps = DynamicElementProps<TextTag> &
  TypographyProps<TextSizeType> &
  BaseProps

const TEXT_SIZE: Record<TextSizeType, string> = {
  extra: 'text-body-extra',
  default: 'text-body-default',
  medium: 'text-body-medium',
  small: 'text-body-small',
  'subtitle-extra': 'text-subtitle-extra font-medium',
  'subtitle-default': 'text-subtitle-default font-medium',
  'subtitle-medium': 'text-subtitle-medium font-medium',
  'subtitle-small': 'text-subtitle-small font-medium',
  'caption-default': 'text-caption-default font-bold',
  'caption-small': 'text-caption-small font-bold',
}

const Text = ({ as = 'p', size, className = '', children }: TextProps) => {
  const TagName = as

  return (
    <TagName className={`${TEXT_SIZE[size]} ${className}`}>{children}</TagName>
  )
}

export default Text
