import type { AxiosError } from 'axios'
import { useQuery } from 'react-query'
import companyApi from './actions'
import { companiesKeys } from './constants'
import { PreferencesConfig } from '@models/company'
import { get } from 'local-storage'
import { PREFERENCES } from '@constants/preferences'
import { COMPANY_EXTERNAL_ID_KEY } from '../auth/tools'

const useCompanyPreferences = () => {
  const companyExternalId: string = get(COMPANY_EXTERNAL_ID_KEY)

  const query = useQuery<PreferencesConfig, AxiosError>(
    companiesKeys.companyPreferences(companyExternalId),
    () => companyApi.getCompanyPreferences(companyExternalId),
    {
      enabled: !!companyExternalId,
      staleTime: Infinity,
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
      initialData: PREFERENCES[companyExternalId],
    },
  )

  return query
}

export default useCompanyPreferences
