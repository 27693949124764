import { useMutation, useQueryClient } from 'react-query'
import depotsApi from './actions'
import { depotsKeys } from './constants'

const useDepotsMutations = (companyId: string) => {
  const queryClient = useQueryClient()

  const createDepot = useMutation(depotsApi.createDepot, {
    onSuccess: () => {
      queryClient.invalidateQueries(depotsKeys.root(companyId))
    },
  })

  const updateDepot = useMutation(depotsApi.updateDepot, {
    onSuccess: () => {
      queryClient.invalidateQueries(depotsKeys.depot(companyId))
    },
  })

  return { createDepot, updateDepot }
}

export default useDepotsMutations
