import axios from 'axios'
import qs from 'qs'
import applyAuthInterceptor from './interceptors/auth'
import applyErrorResponseInterceptor from './interceptors/errorResponse'
import applyRequestDelayInterceptor from './interceptors/requestDelay'

const httpClient = axios.create({
  baseURL: process.env.NEXT_PUBLIC_API_URL,
  paramsSerializer: params =>
    qs.stringify(params, { arrayFormat: 'repeat', skipNulls: true }),
})

// applyRequestDurationLogger(httpClient)
applyErrorResponseInterceptor(httpClient)
applyAuthInterceptor(httpClient)
applyRequestDelayInterceptor(httpClient)

export default httpClient
