import { ordersApi, ordersKeys } from '@api'
import type { OrderFilters } from '@models/order'
import { useQuery } from 'react-query'

const useOrdersCount = (filters: OrderFilters) => {
  const query = useQuery(ordersKeys.ordersCount(filters), () =>
    ordersApi.getOrdersCount(filters),
  )

  return query
}

export default useOrdersCount
