import type { TrackerEvent } from '@models/tracker'
import type { AxiosError } from 'axios'
import ms from 'ms'
import { useQuery } from 'react-query'
import trackerApi from './actions'
import { trackerKeys } from './constants'

const useTrackerLatestEvent = (routeId?: string) => {
  const query = useQuery<TrackerEvent, AxiosError>(
    trackerKeys.trackerLatestEvent(),
    () => trackerApi.getTrackerLatestEvent(routeId!),
    {
      enabled: !!routeId,
      staleTime: ms('1m'),
      refetchInterval: ms('1m'),
      refetchIntervalInBackground: false,
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
    },
  )

  return query
}

export default useTrackerLatestEvent
