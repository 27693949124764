import type { AxiosInstance } from 'axios'
import axios from 'axios'

export default (axiosClient: AxiosInstance) => {
  axiosClient.interceptors.response.use(undefined, error => {
    if (axios.isAxiosError(error)) {
      if (error.response) {
        console.log(
          '❌ AXIOS ERROR [response]',
          error.response?.status,
          error.response?.config.url,
          JSON.stringify(error.response?.data, null, 2),
          error.config?.headers,
        )
      } else if (error.request) {
        console.log('❌ AXIOS ERROR [request]', error)
      } else {
        // something happened in setting up the request that triggered an Error
        console.log('❌ AXIOS ERROR', error.message)
      }
    }

    return Promise.reject(error)
  })
}
