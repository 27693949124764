import type { UserFilters, UserRole } from '@models/user'
import { keyToUrl } from '@tools/common'
import { omit } from 'lodash'

export const usersKeys = {
  root: (companyId?: string) => ['users', companyId ?? null] as const,
  users: (filters: UserFilters) =>
    [...usersKeys.root(filters.companyId), filters] as const,
  user: (id: string) => ['users', id] as const,
  userRoles: () => ['userRoles'] as const,
  usersCount: (filters?: UserFilters) =>
    ['usersCount', omit(filters, ['take', 'skip'])] as const,
  userFeatures: (id: string) => [...usersKeys.user(id), 'features'] as const,
}

export const usersUrls = {
  root: keyToUrl(usersKeys.root()),
  user: (id: string) => keyToUrl(usersKeys.user(id)),
  userRoles: keyToUrl(usersKeys.userRoles()),
  usersCount: keyToUrl(usersKeys.usersCount()),
  userFeatures: (userId: string) => keyToUrl(usersKeys.userFeatures(userId)),
}

export const USER_ROLES: Readonly<UserRole[]> = [
  'RINKAI_OWNER',
  'RINKAI_MANAGER',
  'COMPANY_SUPER_ADMIN',
  'COMPANY_REGIONAL_MANAGER',
  'COMPANY_MANAGER',
  'COMPANY_DISPATCHER',
  'COMPANY_DRIVER',
]

export const AVAILABLE_ROLES: Readonly<UserRole[]> = [
  'COMPANY_SUPER_ADMIN',
  'COMPANY_DISPATCHER',
  'COMPANY_DRIVER',
]

export const getAvailableUserRoles = (role: UserRole): UserRole[] => {
  return USER_ROLES.slice(USER_ROLES.indexOf(role) + 1).filter(role =>
    AVAILABLE_ROLES.includes(role),
  )
}
