import Text from '@components/typography/Text'
import { GlobeFilledIcon } from '@public/icons/sidebar'
import { useTranslation } from 'react-i18next'

const LanguageIcon = () => {
  const { i18n } = useTranslation()

  return (
    <div className="relative inline-block">
      <GlobeFilledIcon className="h-6 w-6" />
      <Text
        as="p"
        size="caption-small"
        className="absolute bottom-0 right-1 text-white text-[8px]"
      >
        {i18n.language.toUpperCase()}
      </Text>
    </div>
  )
}

export default LanguageIcon
