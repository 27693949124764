import TickIconBase from '@public/icons/tick.svg'

interface TickIconProps {
  value: unknown
}

const TickIcon = ({ value }: TickIconProps) => {
  if (typeof value === 'boolean' && value) {
    return <TickIconBase className="w-5 h-5 mx-auto text-primary" />
  }

  return null
}

export default TickIcon
