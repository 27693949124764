import { userApi, usersKeys } from '@api'
import { useQuery } from 'react-query'

const useUsersRole = (companyId: string) => {
  const query = useQuery(usersKeys.userRoles(), () =>
    userApi.getUserRoles(companyId),
  )

  return query
}

export default useUsersRole
