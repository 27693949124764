import type { User } from '@models/user'
import type { AxiosError } from 'axios'
import { useQuery } from 'react-query'
import authApi from './actions'
import { authKeys } from './constants'
import { getAuthCredentialsExist } from './tools'

export const useAuth = () => {
  const query = useQuery<User, AxiosError>(authKeys.root, authApi.getUser, {
    enabled: getAuthCredentialsExist(),
  })

  return query
}

export default useAuth
