import type { UserFeatures } from '@models/user'
import type { AxiosError } from 'axios'
import { useQuery } from 'react-query'
import usersApi from './actions'
import { usersKeys } from './constants'

export const useUserFeatures = (userId?: string) => {
  const query = useQuery<UserFeatures, AxiosError>(
    usersKeys.userFeatures(userId!),
    () => usersApi.getUserFeatures(userId!),
    {
      enabled: !!userId,
      staleTime: Infinity,
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
    },
  )

  return query
}

export default useUserFeatures
