import { routesApi, routesKeys } from '@api'
import { RouteFilters } from '@models/route'
import { useQuery } from 'react-query'

const useRoutesCount = (filters: RouteFilters) => {
  const query = useQuery(routesKeys.routesCount(filters), () =>
    routesApi.getRoutesCount(filters),
  )

  return query
}

export default useRoutesCount
