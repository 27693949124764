import type { WaresToDepotFilters } from '@models/ware'
import { keyToUrl } from '@tools/common'
import { omit } from 'lodash'

export const waresKeys = {
  root: (orderId?: string) => ['wares', orderId ?? null] as const,
  ware: (id: string) => [waresKeys.root(), id] as const,
  waresToDepot: (filters?: WaresToDepotFilters) =>
    [
      'wares',
      'toDepot',
      omit(filters, ['take', 'cursor']),
      { take: filters?.take, cursor: filters?.cursor },
    ] as const,
  waresToDepotCount: (filters?: WaresToDepotFilters) =>
    ['waresToDepotCount', omit(filters, ['take', 'skip'])] as const,
}

export const waresUrls = {
  root: keyToUrl(waresKeys.root()),
  ware: (id: string) => keyToUrl(waresKeys.ware(id)),
  waresToDepot: keyToUrl(waresKeys.waresToDepot()),
  waresToDepotCount: keyToUrl(waresKeys.waresToDepotCount()),
}
