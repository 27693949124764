import { ordersApi, ordersKeys } from '@api'
import type { OrderFromTmsFilters } from '@models/order'
import { useQuery } from 'react-query'

const useTmsOrdersCount = (filters: OrderFromTmsFilters) => {
  const query = useQuery(ordersKeys.tmsOrdersCount(), () =>
    ordersApi.getTmsOrdersCount(filters),
  )

  return query
}

export default useTmsOrdersCount
