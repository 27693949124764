import httpClient from '@api/httpClient'
import type { User, UserFeatures, UserFilters, UserRole } from '@models/user'
import { usersUrls } from './constants'

const getUsers = async (filters: UserFilters) => {
  const { data } = await httpClient.get<User[]>(usersUrls.root, {
    params: filters,
  })
  return data
}

const getUser = async (id: string) => {
  const { data } = await httpClient.get<User>(usersUrls.user(id))
  return data
}

const getUserCount = async (filters: UserFilters) => {
  const { data } = await httpClient.get<number>(usersUrls.usersCount, {
    params: filters,
  })

  return data
}

const getUserRoles = async (id: string) => {
  const { data } = await httpClient.get<UserRole[]>(usersUrls.userRoles, {
    params: { companyId: id },
  })
  return data
}

interface CreateUserRequestData {
  companyId: string
  companyPartnerId?: string
  login: string
  password?: string
  role: UserRole
  phone: string
  fullName: string
  companyIds?: string[]
  depotIds?: string[]
}

const createUser = async (props: Partial<CreateUserRequestData>) => {
  const { data } = await httpClient.post<User>(usersUrls.root, props)
  return data
}

interface UpdateUserRequestData extends Partial<CreateUserRequestData> {
  id: string
}

const updateUser = async ({ id, ...props }: UpdateUserRequestData) => {
  const { data } = await httpClient.put<User>(usersUrls.user(id), props)
  return data
}

const deleteUser = async (id: string) => {
  await httpClient.delete<User>(usersUrls.user(id))
}

const getUserFeatures = async (userId: string) => {
  const { data } = await httpClient.get<UserFeatures>(
    usersUrls.userFeatures(userId),
  )
  return data
}

const updateUserFeatures = async (props: UserFeatures) => {
  const { userId, ...requestData } = props

  const { data } = await httpClient.put<UserFeatures>(
    usersUrls.userFeatures(userId),
    requestData,
  )
  return data
}

export default {
  getUsers,
  getUser,
  createUser,
  updateUser,
  deleteUser,
  getUserCount,
  getUserRoles,
  getUserFeatures,
  updateUserFeatures,
}
