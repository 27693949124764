import queryClient from '@api/queryClient'
import { getAuthCredentials } from '@api/resources/auth/tools'
import type { AxiosInstance } from 'axios'
import createAuthRefreshInterceptor from 'axios-auth-refresh'
import { i18n } from 'next-i18next'
import authApi from '../resources/auth/actions'

export default (axios: AxiosInstance) => {
  axios.interceptors.request.use(config => {
    const { accessToken } = getAuthCredentials()

    if (accessToken) {
      config.headers!['Authorization'] = `Bearer ${accessToken}`
    }

    if (i18n?.language) {
      config.headers!['Accept-Language'] = i18n.language
    }

    return config
  })

  createAuthRefreshInterceptor(
    axios,
    async failedRequest => {
      const { refreshToken } = getAuthCredentials()

      try {
        if (!refreshToken) {
          throw new Error('No refresh token')
        }

        const { accessToken } = await authApi.refreshToken(refreshToken)

        if (!accessToken) {
          throw new Error('Auth tokens are missing')
        }

        failedRequest.response.config.headers[
          'Authorization'
        ] = `Bearer ${accessToken}`
      } catch {
        authApi.logout()
        queryClient.resetQueries()
      }
    },
    {
      statusCodes: [400, 401, 403],
    },
  )
}
