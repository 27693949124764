import type { AxiosError } from 'axios'
import { useQuery } from 'react-query'
import companyApi from './actions'
import { companiesKeys } from './constants'
import { CompanyPartners } from '@models/company'

export interface UseCompanyPartnersData {
  companyPartners: CompanyPartners[]
  companyPartnersMap: Map<string, CompanyPartners>
}

export const selectData = (data: CompanyPartners[]): UseCompanyPartnersData => {
  return {
    companyPartners: data,
    companyPartnersMap: new Map(data.map(company => [company.id, company])),
  }
}

const useCompanyPartners = (companyId: string, partnerId?: string) => {
  const query = useQuery<CompanyPartners[], AxiosError, UseCompanyPartnersData>(
    companiesKeys.companyPartners(companyId),
    () => companyApi.getCompanyPartners(companyId),
    {
      select: selectData,
      enabled: !!companyId && partnerId === null,
      staleTime: Infinity,
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
    },
  )

  return query
}

export default useCompanyPartners
