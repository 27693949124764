import httpClient from '@api/httpClient'
import queryClient from '@api/queryClient'
import type {
  Order,
  OrderFilters,
  OrderFromErpFilters,
  OrderFromTmsFilters,
} from '@models/order'
import { ordersKeys, ordersUrls } from './constants'

const getOrders = async (filters: OrderFilters) => {
  const { data } = await httpClient.get<Order[]>(ordersUrls.orders(), {
    params: filters,
  })

  for (const order of data) {
    queryClient.setQueryData(ordersKeys.order(order.id), order)
  }

  return data
}

const getErpOrders = async (filters: OrderFromErpFilters) => {
  const { data } = await httpClient.get<Order[]>(ordersUrls.erpOrders, {
    params: filters,
  })

  return data
}

const getOrdersCount = async (filters: OrderFilters) => {
  const { data } = await httpClient.get<number>(ordersUrls.ordersCount, {
    params: filters,
  })

  return data
}

const getErpOrdersCount = async (filters: OrderFromErpFilters) => {
  const { data } = await httpClient.get<number>(ordersUrls.erpOrdersCount, {
    params: filters,
  })

  return data
}

const getTmsOrdersCount = async (filters: OrderFromTmsFilters) => {
  const { data } = await httpClient.get<number>(ordersUrls.tmsOrdersCount, {
    params: filters,
  })

  return data
}

const getTmsOrders = async (filters: OrderFromTmsFilters) => {
  const { data } = await httpClient.get<Order[]>(ordersUrls.tmsOrders, {
    params: filters,
  })

  return data
}

const getOrder = async (id: string) => {
  const { data } = await httpClient.get<Order>(ordersUrls.order(id))
  return data
}

const getOrderGoodsDeliveryNote = async (id: string) => {
  const { data } = await httpClient.get<string>(
    ordersUrls.orderGoodsDeliveryNote(id),
  )
  return data
}

const cancelOrder = (orderId: string) =>
  httpClient.post(ordersUrls.cancelOrder(orderId))

export default {
  getOrders,
  getOrder,
  getErpOrders,
  getTmsOrders,
  cancelOrder,
  getOrdersCount,
  getErpOrdersCount,
  getTmsOrdersCount,
  getOrderGoodsDeliveryNote,
}
