import type { Depot } from '@models/depot'
import type { AxiosError } from 'axios'
import { useQuery } from 'react-query'
import depotsApi from './actions'
import { depotsKeys } from './constants'

export interface UseDepotsData {
  depots: Depot[]
  depotsMap: Map<string, Depot>
}

export const selectData = (data: Depot[]): UseDepotsData => {
  return {
    depots: data,
    depotsMap: new Map(data.map(depot => [depot.id, depot])),
  }
}

const useDepots = (companyId: string) => {
  const query = useQuery<Depot[], AxiosError, UseDepotsData>(
    depotsKeys.root(companyId),
    () => depotsApi.getDepots(companyId),
    { select: selectData, enabled: !!companyId },
  )

  return query
}

export default useDepots
