import { classnames } from '@tools/common'

const TableIconButton = ({
  icon,
  onClick,
  iconClassName,
  disabled,
  className,
}: {
  icon: React.ReactNode
  onClick: () => void
  iconClassName?: string
  disabled?: boolean
  className?: string
}) => (
  <button
    disabled={disabled}
    className={classnames(
      'flex items-center justify-center w-[26px] h-[26px] bg-main-100 rounded-lg overflow-hidden',
      {
        'group-hover/row:bg-main-300': !disabled,
      },
      className,
    )}
    onClick={e => {
      e.stopPropagation()
      onClick && onClick()
    }}
  >
    <div
      className={classnames('p-1.5 text-main-900', iconClassName, {
        'hover:bg-main-900 hover:text-white': !disabled,
        'opacity-30 hover:bg-main-100': disabled,
      })}
    >
      {icon}
    </div>
  </button>
)

export default TableIconButton
