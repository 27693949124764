import { Loading } from '@components'
import { classnames } from '@tools/common'
import type { UseQueryResult } from 'react-query'
import Layout from './Layout'

const showLoading = (...queries: UseQueryResult[]) =>
  queries.some(({ isLoading, isIdle }) => isLoading || isIdle)

const showError = (...queries: UseQueryResult[]) =>
  queries.some(({ isError }) => isError)

interface LoaderProps {
  queries: UseQueryResult[]
  noLayout?: boolean
}

const LoaderShow = ({
  queries,
  noLayout,
}: {
  queries: UseQueryResult[]
  noLayout?: boolean
}) => (
  <div
    className={classnames('p-8 flex justify-center align-middle', {
      'h-dvh': !noLayout,
    })}
  >
    {showLoading(...queries) && <Loading />}

    {showError(...queries) && (
      <p className="font-medium text-red-500">Something went wrong</p>
    )}
  </div>
)

export const Loader = ({ queries, noLayout }: LoaderProps) => {
  return noLayout ? (
    <LoaderShow queries={queries} noLayout />
  ) : (
    <Layout>
      <LoaderShow queries={queries} />
    </Layout>
  )
}

export default Loader
