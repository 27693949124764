import type { Company } from '@models/company'
import type { AxiosError } from 'axios'
import { useQuery } from 'react-query'
import companyApi from './actions'
import { companiesKeys } from './constants'

export interface UseCompaniesData {
  companies: Company[]
  companiesMap: Map<string, Company>
}

export const selectData = (data: Company[]): UseCompaniesData => {
  return {
    companies: data,
    companiesMap: new Map(data.map(company => [company.id, company])),
  }
}

const useCompanies = () => {
  const query = useQuery<Company[], AxiosError, UseCompaniesData>(
    companiesKeys.root,
    companyApi.getCompanies,
    { select: selectData },
  )

  return query
}

export default useCompanies
