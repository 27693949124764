import { ordersApi, ordersKeys } from '@api'
import type { OrderFromErpFilters } from '@models/order'
import { useQuery } from 'react-query'

const useErpOrdersCount = (filters: OrderFromErpFilters) => {
  const query = useQuery(ordersKeys.erpOrdersCount(), () =>
    ordersApi.getErpOrdersCount(filters),
  )

  return query
}

export default useErpOrdersCount
