import type { AxiosError } from 'axios'
import { useQuery } from 'react-query'
import ordersApi from './actions'
import { ordersKeys } from './constants'

const useOrderGoodsDeliveryNote = (id: string, enabled?: boolean) => {
  const query = useQuery<string, AxiosError>(
    ordersKeys.orderGoodsDeliveryNote(id),
    () => ordersApi.getOrderGoodsDeliveryNote(id),
    {
      enabled,
      refetchOnMount: false,
      refetchOnReconnect: false,
      refetchOnWindowFocus: false,
      staleTime: Number.MAX_SAFE_INTEGER,
    },
  )

  return query
}

export default useOrderGoodsDeliveryNote
