import httpClient from '@api/httpClient'
import type { TrackerEvent } from '@models/tracker'
import { trackerUrls } from './constants'

const getTrackerLatestEvent = async (routeId: string) => {
  const { data } = await httpClient.get<TrackerEvent>(
    trackerUrls.trackerLatestEvent,
    { params: { routeId } },
  )

  return data
}

export default {
  getTrackerLatestEvent,
}
