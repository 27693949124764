import type { RouteFilters } from '@models/route'
import { keyToUrl } from '@tools/common'
import { omit } from 'lodash'

export const routesKeys = {
  root: ['routes'],
  list: (filters?: RouteFilters) =>
    [
      ...routesKeys.root,
      omit(filters, ['take', 'skip']),
      { take: filters?.take, skip: filters?.skip },
    ] as const,
  finishRoute: (routeId: string) =>
    [...routesKeys.root, routeId, 'finish'] as const,
  processPlan: () => [...routesKeys.root, 'process'] as const,
  routesCount: (filters?: RouteFilters) =>
    ['routesCount', omit(filters, ['take', 'skip'])] as const,
}

export const routesUrls = {
  root: keyToUrl(routesKeys.list()),
  finishRoute: (routeId: string) => keyToUrl(routesKeys.finishRoute(routeId)),
  processPlan: keyToUrl(routesKeys.processPlan()),
  routesCount: keyToUrl(routesKeys.routesCount()),
}
