import { keyToUrl } from '@tools/common'

export const companiesKeys = {
  root: ['companies'] as const,
  company: (id: string) => [...companiesKeys.root, id] as const,
  companyPreferences: (id: string) =>
    [...companiesKeys.company(id), 'preferences'] as const,
  companyPartners: (id: string) =>
    [...companiesKeys.company(id), 'partners'] as const,
  companyPartner: (companyId: string, partnerId: string) => [
    ...companiesKeys.companyPartners(companyId),
    partnerId,
  ],
}

export const companiesUrls = {
  root: keyToUrl(companiesKeys.root),
  company: (id: string) => keyToUrl(companiesKeys.company(id)),
  companyPreferences: (id: string) =>
    keyToUrl(companiesKeys.companyPreferences(id)),
  companyPartners: (id: string) => keyToUrl(companiesKeys.companyPartners(id)),
  companyPartner: (companyId: string, partnerId: string) =>
    keyToUrl(companiesKeys.companyPartner(companyId, partnerId)),
}
