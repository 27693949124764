import { useCompanyPreferences } from '@api'
import LoadingIcon from '@public/icons/loading.svg'
import { classnames } from '@tools/common'

const Loading = () => {
  const preferencesQuery = useCompanyPreferences()

  return (
    <div className="grid min-h-[140px] h-full w-full place-items-center overflow-x-scroll rounded-lg p-6 lg:overflow-visible">
      <LoadingIcon
        className={classnames('w-16 h-16 animate-spin text-primary/50', {
          'text-main-300': preferencesQuery?.data?.settings.theme === 'v1a3131',
        })}
      />
    </div>
  )
}

export default Loading
