'use client'
import { useCompanyPreferences } from '@api'
import { classnames } from '@tools/common'
import { Montserrat, Roboto } from 'next/font/google'
import { useSearchParams } from 'next/navigation'
import { useEffect } from 'react'

const roboto = Roboto({
  subsets: ['latin'],
  style: 'normal',
  weight: ['400', '500', '700'],
  variable: '--font-roboto',
})

const montserrat = Montserrat({
  subsets: ['latin'],
  style: 'normal',
  weight: ['700'],
  variable: '--font-montserrat',
})

const ThemeProvider = ({ children }: { children: React.ReactNode }) => {
  const searchParams = useSearchParams()

  const preferencesQuery = useCompanyPreferences()
  const theme =
    preferencesQuery.data?.settings.theme || searchParams.get('theme')

  const fontClass = theme === 'v1a3131' ? 'font-sans' : 'font-roboto'

  const fontFamilyStyle =
    theme === 'v1a3131'
      ? 'Arial, Helvetica, sans-serif'
      : 'var(--font-roboto), Arial, Helvetica, sans-serif'

  useEffect(() => {
    if (!!theme) {
      document.documentElement.setAttribute('data-theme', theme)
    } else {
      document.documentElement.removeAttribute('data-theme')
    }
  }, [theme])

  return (
    <div
      className={classnames(
        'relative z-0',
        montserrat.variable,
        roboto.variable,
        fontClass,
      )}
      style={{ fontFamily: fontFamilyStyle }}
    >
      {children}
    </div>
  )
}

export default ThemeProvider
