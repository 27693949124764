import queryClient from '@api/queryClient'
import type { FinanceFilters } from '@models/finance'
import { useMutation } from 'react-query'
import { financesApi, financesKeys } from '.'

const useFinancesMutations = (filters?: FinanceFilters) => {
  const addFinances = useMutation(financesApi.addOperation, {
    onSuccess: async () => {
      await queryClient.invalidateQueries(financesKeys.list(filters))
    },
  })

  return { addFinances }
}

export default useFinancesMutations
