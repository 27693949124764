import { useAuth, useCompanies, useCompanyPreferences } from '@api'
import { COMPANY_ID_KEY } from '@api/resources/auth/tools'
import { Loader } from '@components'
import Button from '@components/buttons/Button'
import Layout from '@components/Layout'
import CompaniesTable from '@components/tables/CompaniesTable'
import PlusIcon from '@public/icons/plus.svg'
import { getRouteToRedirect } from '@tools/homepage'
import { get, set } from 'local-storage'
import type { GetServerSideProps } from 'next'
import { useTranslation } from 'next-i18next'
import { serverSideTranslations } from 'next-i18next/serverSideTranslations'
import { useRouter } from 'next/router'
import { useEffect } from 'react'

export const ACQUIRING_PROVIDERS = ['none', 'mono'] as const
export const FISCALIZATION_PROVIDERS = ['none', 'checkbox'] as const
export const RECALCULATION_STATUSES = [
  'PARTIAL_RETURN',
  'NOT_DELIVERED',
] as const

const HomePage = () => {
  const { t } = useTranslation()

  const router = useRouter()

  const companyId = get<string>(COMPANY_ID_KEY)

  const preferencesQuery = useCompanyPreferences()

  const routeToRedirect = getRouteToRedirect(companyId, preferencesQuery.data)

  useEffect(() => {
    if (routeToRedirect) {
      router.push(routeToRedirect)
    }
  }, [companyId, preferencesQuery.data])

  const companiesQuery = useCompanies()
  const authQuery = useAuth()

  if (!companiesQuery.isSuccess || !authQuery.isSuccess) {
    return <Loader queries={[companiesQuery, authQuery]} />
  }

  if (
    preferencesQuery.data &&
    preferencesQuery.data.settings &&
    preferencesQuery.data.settings.homePage &&
    preferencesQuery.data.settings.homePage !== '/companies'
  ) {
    return null
  }

  const onClickRow = (companyId: string) => {
    set(COMPANY_ID_KEY, companyId)
    router.push(`/companies/${companyId}/orders`)
  }

  return (
    <Layout
      title={t('pages.home.title')}
      headerChildren={
        <Button
          onClick={() => router.push('companies/create')}
          label={t('pages.home.create')}
          icon={<PlusIcon className="w-[22px] h-[22px]" />}
        />
      }
    >
      <CompaniesTable
        data={companiesQuery.data.companies}
        onClickRow={onClickRow}
        onClickEdit={companyId => router.push(`companies/${companyId}/edit`)}
      />
    </Layout>
  )
}

export default HomePage

export const getServerSideProps: GetServerSideProps = async ({ locale }) => {
  return {
    props: {
      ...(await serverSideTranslations(locale!)),
    },
  }
}
