import { keyToUrl } from '@tools/common'

export const authKeys = {
  root: ['auth'] as const,
  login: () => [...authKeys.root, 'login'] as const,
  refresh: () => [...authKeys.root, 'refresh'] as const,
  me: () => [authKeys.root, 'me'] as const,
}

export const authUrls = {
  root: keyToUrl(authKeys.root),
  login: keyToUrl(authKeys.login()),
  refresh: keyToUrl(authKeys.refresh()),
  me: keyToUrl(authKeys.me()),
}
