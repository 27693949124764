import { useAuthMutations, useCompanyPreferences } from '@api'
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuTrigger,
} from '@components/shadcn/ui/dropdown-menu'
import Text from '@components/typography/Text'
import useFreshChatWidget from '@hooks/useFreshChatWidget'
import { LogOutIcon, ProfileIcon } from '@public/icons/sidebar'
import { classnames } from '@tools/common'
import { useTranslation } from 'next-i18next'
import Link from 'next/link'
import { useRouter } from 'next/router'

interface ProfilePopUpProps {
  fullName?: string
  login?: string
}

const ProfilePopUp = ({ fullName, login }: ProfilePopUpProps) => {
  const { t } = useTranslation()
  const router = useRouter()

  const { destroyChat } = useFreshChatWidget()

  const preferencesQuery = useCompanyPreferences()

  const authMutations = useAuthMutations()

  const logout = () => {
    authMutations.logout.mutateAsync().then(() => {
      destroyChat()

      if (preferencesQuery.data?.settings.theme === 'v1a3131') {
        router.push('/login?theme=v1a3131')
      } else {
        router.push('/login')
      }
    })
  }

  return (
    <DropdownMenu>
      <DropdownMenuTrigger>
        <div
          className={classnames(
            'w-full h-[80px] flex justify-center cursor-pointer items-center hover:bg-white/10 hover:shadow-2xl aspect-square',
            {
              'bg-white/20 shadow-2xl': router.asPath === '/login',
            },
          )}
        >
          <ProfileIcon className="h-5 w-5" />
        </div>
      </DropdownMenuTrigger>
      <DropdownMenuContent
        side="right"
        align="end"
        sideOffset={0}
        className="p-0"
      >
        <ul
          tabIndex={0}
          className="pb-2 bg-white text-primary rounded-tr-md rounded-br-md w-60"
        >
          <Link
            href="/profile"
            className="px-3 py-4 flex flex-col gap-0.5 bg-main-300 hover:no-underline"
          >
            <Text as="p" size="subtitle-medium" className="text-primary">
              {fullName}
            </Text>

            <Text as="p" size="caption-default" className="text-text-500">
              {login}
            </Text>
          </Link>

          <button
            onClick={logout}
            className="w-full flex gap-2 cursor-pointer items-center hover:bg-red-500/10 mt-1 pb-1.5 px-3 py-3"
          >
            <LogOutIcon className="h-5 w-5" />
            <Text as="p" size="caption-default" className="text-danger-500">
              {t('ui.logout')}
            </Text>
          </button>
        </ul>
      </DropdownMenuContent>
    </DropdownMenu>
  )
}

export default ProfilePopUp
