export { default as DepotIcon } from './depot.svg'
export { default as FinancesIcon } from './finances.svg'
export { default as GlobeFilledIcon } from './globe-filled.svg'
export { default as GlobeIcon } from './globe.svg'
export { default as GoRampIcon } from './goramp.svg'
export { default as LogOutIcon } from './logout.svg'
export { default as OrderIcon } from './orders.svg'
export { default as PartnersIcon } from './partner.svg'
export { default as ProfileIcon } from './profile.svg'
export { default as ReportsIcon } from './reports.svg'
export { default as RouteIcon } from './routes.svg'
export { default as SupportIcon } from './support.svg'
export { default as UsersIcon } from './users.svg'
export { default as WaresToDepotIcon } from './wares.svg'
