import { keyToUrl } from '@tools/common'

export const trackerKeys = {
  tracker: () => ['tracker'] as const,
  trackerLatestEvent: () => [...trackerKeys.tracker(), 'latest'] as const,
}

export const trackerUrls = {
  tracker: keyToUrl(trackerKeys.tracker()),
  trackerLatestEvent: keyToUrl(trackerKeys.trackerLatestEvent()),
}
