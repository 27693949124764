import httpClient from '@api/httpClient'
import queryClient from '@api/queryClient'
import type {
  AcceptWaresToDepot,
  Ware,
  WaresToDepotFilters,
  WareToDepot,
} from '@models/ware'
import { ordersUrls } from '../orders'
import { waresKeys, waresUrls } from './constants'

const acceptWaresToDepot = async (props: AcceptWaresToDepot) => {
  const { data } = await httpClient.post<AcceptWaresToDepot>(
    ordersUrls.acceptWaresToDepot,
    props,
  )
  return data
}

const getWares = async (orderId: string) => {
  const { data } = await httpClient.get<Ware[]>(waresUrls.root, {
    params: { orderId },
  })

  for (const order of data) {
    queryClient.setQueryData(waresKeys.ware(order.id), order)
  }

  return data
}

const getWare = async (id: string) => {
  const { data } = await httpClient.get<Ware>(waresUrls.ware(id))

  return data
}

const getWaresToDepot = async (filters: WaresToDepotFilters) => {
  const { data } = await httpClient.get<WareToDepot[]>(waresUrls.waresToDepot, {
    params: filters,
  })
  return data
}

const getWaresToDepotCount = async (filters: WaresToDepotFilters) => {
  const { data } = await httpClient.get<number>(waresUrls.waresToDepotCount, {
    params: filters,
  })
  return data
}

export default {
  getWares,
  getWare,
  getWaresToDepot,
  acceptWaresToDepot,
  getWaresToDepotCount,
}
