import httpClient from '@api/httpClient'
import axios from 'axios'
import type { FC } from 'react'
import toast from 'react-hot-toast'
import type { QueryFunction } from 'react-query'
import {
  QueryClientProvider as LibraryQueryClientProvider,
  QueryClient,
} from 'react-query'

const defaultQueryFunction: QueryFunction = async ({ queryKey }) =>
  httpClient.get(queryKey.join('/')).then(resp => resp.data)

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      queryFn: defaultQueryFunction,
    },
    mutations: {
      onMutate: () => {
        const toastId = toast.loading('')
        return { toastId }
      },
      onSettled: (_, error, __, { toastId }: any) => {
        toast.dismiss(toastId)
        if (error) {
          if (axios.isAxiosError(error) && error.response?.data) {
            const { message } = error.response.data as { message: string }

            toast.error(message)
          } else {
            toast.error('')
          }
        } else {
          toast.success('')
        }
      },
    },
  },
})

export const QueryClientProvider: FC<{ children?: React.ReactNode }> = ({
  children,
}) => (
  <LibraryQueryClientProvider client={queryClient}>
    {children}
  </LibraryQueryClientProvider>
)

export default queryClient
